.table {
    font-size: 0.9rem;
    border-radius: 8px;
    overflow: hidden;
}

th {
    text-transform: capitalize;
    padding: 3px;
}

td {
    padding: 3px;
    text-align: center;
}

.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.print-btn {
    background-color: green;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

.print-btn:hover {
    background-color: #0056b3;
}

.expand-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.expand-btn:hover {
    opacity: 0.7;
}

.expand-btn-container {
    text-align: center;
    width: 50px;
}

.expand-btn {
    background-color: #28a745 !important;
    /* Green background */
    color: white;
    /* White text */
    border: none;
    /* Remove border */
    cursor: pointer;
    border-radius: 4px;
    /* Rounded corners */
    font-size: 14px;
}

.expand-btn:hover {
    background-color: #218838;
    /* Darker green on hover */
}