.double-range-slider-container {
  position: relative;
  font-size: 16px;
  color: #fff;
  width: 400px;
  height: 100%;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.double-range-slider-rail {
  background-color: #8c8c8c;
  width: 400px;
  height: 5px;
  border-radius: 50px;
  z-index: 0;
}

.double-range-slider-track {
  background-color: #008000;
  width: 400px;
  height: 5px;
  border-radius: 50px;
  position: absolute;
  top: 0px;
}

.double-range-slider-ball {
  background-color: #f5f5f5;
  outline: #008000 5px solid;
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: calc((-5px * calc((25px - 10px) / 10px)));
}

.double-range-slider-active {
  z-index: 4;
}

.double-range-slider-tooltip {
  background-color: #008000;
  visibility: hidden;
  display: flex;
  align-items: center;
  height: 20px;
  width: auto;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px 20px;
  flex-wrap: nowrap;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.double-range-slider-ball.double-range-slider-tooltip {
  left: 50%;
  transform: translateX(-50%);
}

.double-range-slider-over {
  bottom: calc(25px / 2 + 35px);
}
.double-range-slider-under {
  top: calc(25px / 2 + 35px);
}

.double-range-slider-mid {
  bottom: calc(25px / 2 + 25px);
}

.double-range-slider-over::after {
  border-color: #f3bc3e transparent transparent transparent;
  top: 100%;
}
.double-range-slider-under::after {
  border-color: transparent transparent #f3bc3e transparent;
  bottom: 100%;
}

.double-range-slider-tooltip::after {
  content: " ";
  position: absolute;
  margin-left: var(--after-margin-left, -10px);
  border-width: 10px;
  border-style: solid;
  pointer-events: none;
  left: var(--after-left, 50%);
}

.double-range-slider-text-holder {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.double-range-slider-step {
  position: absolute;
  z-index: 1;
  height: 10px;
  width: 5px;
  border-radius: 30px;
  background-color: #f5f5f5;
}
