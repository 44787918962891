.App {
  text-align: center;
}

.sidebar{
  text-align: left;
  width: 220px;
}

.list-group a:hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.mynav{
  margin-left: 10px;
}

.mySearch{
  max-width: 199px;
}

.filterPanel{
  background-color:lightgrey;
  
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 20px;
}

.col-3{
  padding: 5px;
}

.spinner{
  margin: 10px
}

.heading{
  color:green
}

.fillerImage{
  padding: 100px;
}
.goldLogo{
  padding: 30px;
  width: 40%;
  max-width: 400px;
  min-width: 200px;
}

.noData{
  padding: 20px;
}
.professionDropDown{
  max-height: 200px;
    overflow-y: scroll;
}

.pagination{
  position: absolute;
  bottom: 0;
  justify-content: center;
  width: 90%;
}
table{
  padding-top: 300px;
}

.error{
  color: red;
}

.loginbutton{
  float: left;
  background-color: transparent;
  color: blue;
  border-color: transparent;
  border-radius: 8px;
}
.loginbutton:hover{
  border: 1px solid gray;
}

.cursive{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.progressbar {
  width: 100%;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
  text-align: center;
  padding: 9pxs;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(98, 0, 255);
}

.webLeadsCount{
  font-size: large;
}

.webLeadsCount:hover{
  cursor: pointer;
  font-size: 25px;
}

.separator{
  color:green
  
}

.sizeControl{
  float: center;
}

.deleteButton:hover{
  color: green;
}

.pencil:hover{
  color: green;
  cursor: pointer;
}

.popup{
  z-index: 1000;
}

/* .bgImage {
  
} */
